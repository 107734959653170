import React from 'react'
import T from 'prop-types'
import Helmet from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'

export const SEO = props => {
  const {markdownRemark: {frontmatter: {seo}}} = useStaticQuery(graphql`
    query DefaultSEO {
      markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
        frontmatter {
          seo {
            title
            description
            image {
              childImageSharp {
                fixed(width: 1200, height: 1200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            twitter
          }
        }
      }
    }
  `)

  const title = props.title || seo.title
  const description = props.description || seo.description
  const image = seo.image.childImageSharp.fixed.src
  const url = `https://eshprint.com${props.pathname || '/'}`

  return (
    <Helmet title={title}>
      <html lang="en"/>
      <meta charSet="utf-8"/>
      <meta name="description" content={description}/>
      <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no"/>
      <meta name="image" content={image}/>
      <meta property="og:url" content={url}/>
      <meta property="og:title" content={title}/>
      <meta property="og:description" content={description}/>
      <meta property="og:image" content={image}/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:creator" content={`@${seo.twitter}`}/>
      <meta name="twitter:title" content={title}/>
      <meta name="twitter:description" content={description}/>
      <meta name="twitter:image" content={image}/>
      <meta name="google-site-verification" content="TlXYsTH22HQ39b_EJgpiC_b9EQs0LNI10eXgTz_s2bc"/>
    </Helmet>
  )
}

SEO.propTypes = {
  pathname: T.string,
  title: T.string,
  description: T.string,
}

export default SEO
